<template>
  <div class="vacancy-item">
    <div class="vacancy-item__header">
      <div class="vacancy-item__info">
        <h3 class="vacancy-item__title">
          <IconUser class="icon" />
          <span>{{ vacancy.title }}</span>
        </h3>
        <SkillChips class="vacancy-item__chips" small :skills="chips" :display-count="3" />
      </div>
      <VacancyMetaBadges :vacancy="vacancy" />
    </div>
    <p class="vacancy-item__desc">
      {{ vacancy.description }}
    </p>
  </div>
</template>

<script setup>
import { IconUser } from '@tabler/icons-vue'

const props = defineProps({
  vacancy: Object
})

const chips = computed(
  () => []
    .concat(props.vacancy.skill_level ? [{ id: -1, name: props.vacancy.skill_level.title }] : [])
    .concat(props.vacancy.skills)
)
</script>

<style scoped lang="scss">
.vacancy-item {
  padding: 24px 0;
  border-bottom: 1px solid var(--color-separator);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px 24px;
  }
  &__info {
    display: flex;
    align-items: center;
    gap: 12px 16px;
    flex-wrap: wrap;
    max-width: 100%;
  }
  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-elements-primary);
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: 920px) {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
    .icon {
      width: 18px;
      height: 18px;
      color: var(--color-elements-secondary);
      flex-shrink: 0;
    }
  }
  &__desc {
    margin-top: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 60px;
    overflow: hidden;
  }
}
</style>
