<template>
  <NuxtLink
    :to="to"
    custom
    v-slot="{ href }"
  >
    <SearchResultCard class="project" v-bind="$attrs" @click="link ? open(href) : emit('click')" :link="link">
      <a v-if="link" class="project__anchor" :href="href" target="_blank"></a>
      <div class="project__body">
        <div v-if="project.cover" class="project__cover-container">
          <img :src="project.cover.url" :alt="project.title" class="project__cover">
        </div>
        <div class="project__content">
          <div class="project__header">
            <h1 class="project__title">{{ project.title }}</h1>
            <MetaBadgeList>
              <MetaBadge v-if="project.period" primary>
                <IconClock class="icon"/>
                за {{ project.period }} {{ declOfNum(project.period, ['день', 'дня', 'дней']) }}
              </MetaBadge>
              <ProjectStatusChip :project="project"/>
            </MetaBadgeList>
          </div>
          <ResponseVacancies class="project__responded"
                             :vacancies="project.vacancies.filter(v => v.response_status !== null)" outgoing
                             :invitation="project.response_status === 'request'"/>
          <Expandable
            class="project__desc-container"
            :max-height="72"
            expand-text="Развернуть полное описание"
            shrink-text="Свернуть полное описание"
          >
            <p class="project__desc">{{ project.description }}</p>
          </Expandable>
          <slot v-if="$slots.default"/>
          <div v-else class="project__vacancies">
            <h2 class="project__vacancies__title">Требуются специалисты:</h2>
            <VacancyItem v-for="vacancy in project.vacancies" :key="vacancy.id" :vacancy="vacancy"/>
          </div>
        </div>
      </div>
      <div class="project__footer">
        <MetaBadgeList separator>
          <MetaBadge primary>
            <IconUser class="icon"/>
            {{ project.owner.first_name }} {{ project.owner.last_name }}
          </MetaBadge>
          <ProjectViewsBadge :count="project.views_count"/>
          <ProjectResponsesBadge v-if="project.responses_count > 0" :count="project.responses_count"/>
          <ProjectCreatedAtBadge :datetime="project.created_at"/>
        </MetaBadgeList>
        <slot v-if="$slots.action" name="action"/>
        <BaseButton v-else color-primary @click.stop :to="to" target="_blank">Подробнее</BaseButton>
      </div>
    </SearchResultCard>
  </NuxtLink>
</template>

<script setup>
import {IconClock, IconUser} from '@tabler/icons-vue'

defineOptions({inheritAttrs: false})
const props = defineProps({
  project: Object,
  link: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['click'])

function open(href) {
  window.open(href, '_self')
}

const user = inject('user')
const to = computed(() => ({
  name: props.project.owner_id === user.value?.id ? 'project-responses' : 'project-view',
  params: {id: props.project.id}
}))
</script>

<style scoped lang="scss">
.project {
  padding: 32px 24px 24px;
  @media (max-width: 920px) {
    padding: 16px 15px;
  }

  &__anchor {
    display: none;
  }

  &__body {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    @media (max-width: 920px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__cover-container {
    width: 174px;
    flex-shrink: 0;
    @media (max-width: 920px) {
      width: 100%;
    }
  }

  &__cover {
    width: 100%;
    height: 120px;
    border-radius: 8px;
    object-fit: cover;
    @media (max-width: 920px) {
      height: auto;
      aspect-ratio: 1.45;
      border-radius: 12px;
    }
  }

  &__content {
    flex: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: flex-start;
    margin-bottom: 24px;
    @media (max-width: 920px) {
      margin-bottom: 16px;
      flex-direction: column;
      gap: 8px;
      :deep(.meta-badge-list) {
        flex-direction: row-reverse;
      }
    }
  }

  &__title {
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
    color: var(--color-elements-primary);
    @media (max-width: 920px) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  &__responded {
    margin: 24px 0;
    @media (max-width: 920px) {
      margin: 16px 0;
    }
  }

  &__vacancies {
    margin-top: 24px;

    &__title {
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
    }
  }

  &__desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-elements-primary);
    white-space: pre-line;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px 16px;
    margin-top: 24px;
    @media (max-width: 920px) {
      flex-direction: column-reverse;
      align-items: stretch;
      :deep(.meta-badge):not(:first-child):not(:last-child) {
        display: none;
      }
    }
  }

  :deep(.project__btn) {
    @media (max-width: 920px) {
      width: 100%;
    }
  }
}
</style>
